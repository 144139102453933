<template>
	<div class="teachingHangul">
		<div class="letter" ref="letter">
			{{ infos.letter }}
		</div>
		<br>
		<div class="flex">
			<div class="pronounce" ref="pronounce" v-html="infos.pronounce" />
			<img
				class="soundOn"
				ref="soundOn"
				src="/img/speaker.png"
				@click="playSound"
			>
		</div>
		<ContinueButton @click="next" ref="next" />
	</div>
</template>

<script>
import { gsap } from 'gsap/all'
import { Howl } from 'howler'
import ContinueButton from '@/components/ContinueButton.vue'

export default {
	name: 'TeachingHangul',
	emits: ['done'],
	props: {
		infos: {
			type: Object,
			required: true
		}
	},
	components: {
		ContinueButton
	},
	mounted () {
		this.transitionIn()
	},
	methods: {
		playSound () {
			var sound = new Howl({
				src: [`/audio/hangul/${this.infos.translate}.mp3`]
			})
			sound.play()
		},
		next () {
			this.$emit('done')
		},
		transitionIn (cb) {
			gsap.from(this.$refs.letter, { opacity: 0, scale: 0.8 })
			gsap.from(this.$refs.pronounce, { delay: 0.2, opacity: 0, x: -20, onComplete: this.playSound })
			gsap.from(this.$refs.soundOn, { delay: 0.4, opacity: 0 })
			this.$refs.next.show(() => { if (cb) { cb() } })
		},
		transitionOut (cb) {
			this.$refs.next.hide()
			gsap.to(this.$refs.soundOn, { delay: 0.1, opacity: 0, x: 20 })
			gsap.to(this.$refs.pronounce, { delay: 0.2, opacity: 0, x: 20 })
			gsap.to(this.$refs.letter, { delay: 0.3, opacity: 0, scale: 0.8, onComplete: () => { if (cb) { cb() } } })
		}
	}
}
</script>

<style lang="stylus" scoped>
	.teachingHangul
		user-select none
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)

		.flex
			display: flex
			flex-wrap: wrap
			flex-direction row
			align-items center
			align-content center
			justify-content: center
			-webkit-box-direction: normal
			-webkit-box-pack: center
			margin-bottom 60px
			div, img
				align-self: center

		.letter
			padding 30px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 60px
			height 60px
			margin-bottom 20px
		.soundOn
			height 20px
			width 20px
			padding 10px
			border 1px solid #fff
			border-radius: 50%
			display block
			cursor pointer
		.pronounce ::v-deep
			font-size: 24px
			margin-right 20px
			color #fff
			position relative
			span
				background #fff
				color #000
				padding 4px 10px
				font-size 1.2em
				display inline-block
			&:after
				content ""
				display block
				position absolute
				bottom -5px
				left 0
				width 100%
				height 1px
				background #fff

</style>
