<template>
	<div class="hangul">
		<component
			v-if="slides!=null"
			:is="slide"
			ref="slide"
			:infos="slides[slideIdx]"
			:key="slideIdx"
			@done="onNext"
		/>
		<Success v-if="isCompleted" @finish="onEnd" />
	</div>
</template>

<script>

import router from '@/router'
import TeachingHangul from './TeachingHangul'
import HangulExercice from './HangulExercice'
import Success from '@/components/Success'
import UserData from '@/data/UserData'

export default {
	name: 'Hangul',
	components: {
		Success
	},
	emits: ['completed'],
	props: {
		jsonID: {
			type: String,
			default: 'hangul01.json'
		}
	},
	data () {
		return {
			isCompleted: false,
			slideIdx: 0,
			btnRefs: [],
			slides: null
		}
	},
	mounted () {
		fetch('/data/' + this.$route.params.id + '.json')
			.then((response) => response.json()).then((json) => {
				this.slides = json
			})
	},
	computed: {
		slide () {
			switch (this.slides[this.slideIdx].type) {
			case 'HangulExercice':
				return HangulExercice
			case 'teaching':
			default:
				return TeachingHangul
			}
		}
	},
	methods: {
		onEnd () {
			router.go(-1)
		},
		transitionIn () {
		},
		transitionOut () {
		},
		onNext () {
			this.nextSlide()
		},
		nextSlide () {
			this.$refs.slide.transitionOut(() => {
				if (this.slideIdx === this.slides.length - 1) {
					UserData.finishStage(this.$route.params.id)
					this.isCompleted = true
				} else {
					this.slideIdx++
					this.$refs.slide.transitionIn()
				}
			})
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut()
		setTimeout(next, 400)
	}
}
</script>
<style lang="stylus" scoped>
.hangul
	min-height 100%
	height 100%
</style>
